import React from "react"
import Layout from "../components/layout.js"
const Privacy = () => {

  return (
    <Layout landingPage="/client-terms" />
  )
}

export default Privacy
